<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技荣获第七届全球青年创新大会“金领奖”
              </p>
              <div class="fst-italic mb-2">2023年12月11日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >荣获奖项</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/64/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    12月7日，“第七届全球青年创新大会（GYIC）”在京成功召开，“金领奖”年度颁奖盛典同期举行。非凸科技合伙人朱为受邀参加并发表主题演讲，同时，非凸科技荣获“金领奖”殊荣。
                  </p>
                  <p>
                    大会重点关注“进化中的新商业”，邀请工信部和北京市政府领导、行业协会专家、优秀企业家、新锐创业者、知名经济学者，以全球化视角，围绕“新趋势，新机会，新挑战”议题展开前瞻讨论，谏言中国产业转型升级与企业创新发展的新路径与新方向。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/64/02-奖杯.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/64/03-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在新科技领域，非凸科技正积极拥抱新技术、新场景、新变化，不断探索AI在金融科技领域的应用，持续打造优质产品和解决方案，以科技助推金融行业数智化发展。会上，朱为表示，数智交易行业是一个需要不断探索新鲜事物的行业，需要持续面对新的挑战。在过去十几年里，中国数智投资取得了长足进展，包括人工智能等在内的新技术迭代和应用，为行业发展起到了非常大的推进作用。非凸科技正深度聚焦中国金融市场，以Rust全栈技术、AI算法力、金融理解力等为核心驱动，构建新一代全业务智能交易平台，为客户提供一站式智能交易领域服务解决方案。
                  </p>
                  <p>
                    砥砺前行，创新不止。未来，非凸科技也将秉承行业初心，以最具实力的技术及产品研发能力，持续推动数智化交易未来。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News64",
};
</script>

<style></style>
